<template>
  <div>
    <headers />
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item  to="/school/index">培训学院</el-breadcrumb-item>
        <el-breadcrumb-item :to="`/school/index?id=${classifyId}`" v-if="classifyName">{{classifyName}}</el-breadcrumb-item>
        <el-breadcrumb-item  to="/school/index">{{details.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title">
      {{ details.name }}
    </div>
    <div class="tab">
      <div
        class="tabCard"
        v-if="details.equityClassifyNames.length == 1"
        :style="'background:' + backGround[details.equityClassifyNames[0]]+';color:'+colorEnum[details.equityClassifyNames[0]]"
      >
        <span
          v-for="(items, indexs) in details.equityClassifyNames"
          :key="indexs"
          >{{ items }}</span
        >专属课程
      </div>
      <div class="tabCard" v-else  style="background:rgba(0, 216, 195, 0.1);color:#00D8C3;">
        <span
          v-for="(items, indexs) in details.equityClassifyNames"
          :key="indexs"
          ><span v-if="indexs!=0">/</span>{{ items }}</span
        >可查看
      </div>
      <div class="num">学习次数：{{ details.studyNum }}</div>
    </div>

    <div class="content">
      <div class="video" style="position: relative">
        <video
          class="video"
          muted
          preload
          ref="videoPlayer"
          @play="onPlay"
          @pause="onPause"
          controls
        >
          <source :src="videoUrl" type="video/mp4" />
        </video>
        <img
          v-if="showBtn"
          @click="togglePlay"
          class="playIcon"
          src="@/assets/images/play.png"
        />
      </div>

      <div class="rightBox">
        <div v-for="(item, index) in details.details" :key="index">
          <div
            class="cell"
            @click="changeAct(index)"
            :style="actIndex === index ? { background: '#E5FBF9' } : {}"
          >
            <div
              class="cellTit1"
              :style="actIndex === index ? { color: '#00D8C3 !important' } : {}"
            >
              {{ index + 1 }}.{{ item.fileName }}
            </div>
            <div class="cellTit2">
              时长：{{ formattedTime(item.durationNum) }}
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { videoView } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      backGround: {
        L1: "rgba(0, 216, 195, 0.1)",
        L2: "rgba(240, 193, 11, 0.1)",
        L3: "rgba(71, 177, 226, 0.1)",
        L4: "rgba(147, 93, 228, 0.1)",
      },
      colorEnum: {
        L1: "#00D8C3",
        L2: "#F0C10B",
        L3: "#47B1E2",
        L4: "#935DE4",
      },
      actIndex: 0,
      showBtn: true,
      id: "",
      details: {},
      list: [],
      videoUrl: "",
      classifyName:'',
      classifyId:''
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.classifyName = this.$route.query.classifyName;
    this.classifyId = this.$route.query.classifyId;
    this.getDetails();
  },
  mounted() {},
  methods: {
    formattedTime(val) {
      let minutes = Math.floor(val / 60);
      let remainingSeconds = val % 60;
      if (minutes > 0) {
        return `${minutes}分${remainingSeconds}秒`;
      } else {
        return `${remainingSeconds}秒`;
      }
    },
    getDetails() {
      videoView(this.id).then((res) => {
        this.details = res.data;
        this.videoUrl = this.details.details[this.actIndex].fileUrl;
        console.log("13", this.videoUrl);
        this.$refs.videoPlayer.load();
        this.showBtn = true;
        // this.$refs.videoPlayer.play().catch((error) => {
        //   console.error("自动播放失败:", error);
        // });
      });
    },
    changeAct(val) {
      this.actIndex = val;
      this.videoUrl = this.details.details[this.actIndex].fileUrl;
      this.$refs.videoPlayer.load();
      this.showBtn = true;
      // this.$refs.videoPlayer.play().catch((error) => {
      //   console.error("自动播放失败:", error);
      // });
    },
    onPlay() {
      // console.log("播放");
      this.showBtn = false;
    },
    onPause() {
      // console.log("暂停");
      this.showBtn = true;
    },
    togglePlay() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.custom-breadcrumb-item:hover{
  color: #fff !important;
}
.title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 26px;
  color: #00161a;
  line-height: 37px;
  margin-left: 50px;
  margin-top: 30px;
}
.tab {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .tabCard {
    padding: 0 7px;
    height: 26px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    margin-left: 50px;
    margin-right: 15px;
  }
  .num {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #525f66;
    line-height: 20px;
  }
}
.content {
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 54px;
  .video {
    width: 1296px;
    height: 729px;
    background-color: #00161a;
  }
  .playIcon {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 304px;
    left: 588px;
    cursor: pointer;
  }
  .rightBox {
    width: 524px;
    height: 729px;
    background: #ffffff;
    .cell {
      width: 100%;
      padding: 20px 30px;
      margin: 0 auto;
      box-sizing: border-box;
      cursor: pointer;
      .cellTit1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #00161a;
        line-height: 25px;
      }
      .cellTit2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #829199;
        line-height: 20px;
        margin-top: 15px;
      }
    }
    .line {
      width: 464px;
      height: 1px;
      background: #e6edf0;
      margin: 0 auto;
    }
  }
}
</style>